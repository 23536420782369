import { DBFactory } from "classes/database/db_factory";
import { BaseModel } from "classes/models/base.model";

export type Demos = Demo[];

type DemoConstructorParams = {
  id?: string;
  name: string;
};

export class Demo extends BaseModel {
  id?: string;
  name: string;

  constructor(params: DemoConstructorParams) {
    super(params);
    this.id = params.id;
    this.name = params.name;
  }

  static fromMap(map: any): Demo {
    return new Demo(map);
  }

  async save() {
    this.modelDatabaseConfig = {
      collection: `demos`,
      path: `demos/${this.id}`,
    };

    const db = DBFactory.createDatabase();
    this.id = await db.save(this.toMap(), this.modelDatabaseConfig);
  }
}
