import type {
  SubmittedDocument,
  SubmittedDocuments,
} from "types/SubmittedDocument";
import { Assignment, type Assignments } from "../assignments/assignment.model";
import { Classroom, type Classrooms } from "../classrooms/classroom.model";
import { Student, type Students } from "../students/student.model";
import { BaseModel } from "../base.model";
import { Demo } from "./demo.model";

// @ts-ignore
import HumanNames from "human-names";

import { DBFactory } from "classes/database/db_factory";
import {
  CollectionReference,
  type DocumentData,
  DocumentReference,
  Timestamp,
  WriteBatch,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { Revision } from "types/Revision";
import { Paragraph } from "types/Paragraph";
import { ClassroomsService } from "../classrooms/classrooms.service";
import { AssignmentsService } from "../assignments/assignments.service";
import { StudentsService } from "../students/students.service";
import { CriteriaGroups } from "../criteria/criteria-group.model";
import { user } from "firebase-functions/v1/auth";

export class DemosService {
  static async listDemos() {
    const db = DBFactory.createDatabase();
    const data = await db.list({
      collection: "demos",
      path: "demos",
    });

    return data.map((map) => {
      return Demo.fromMap(map);
    });
  }

  static generateWeightedRandom() {
    const random = Math.random();

    if (random < 0.05) {
      // Generate a number between 0 and 4 (inclusive of 0 and exclusive of 5)
      return Math.floor(Math.random() * 5);
    } else if (random < 0.1) {
      // Generate a number between 5 and 9 (inclusive of 5 and exclusive of 9)
      return Math.floor(5 + Math.random() * 4);
    } else {
      // Generate a number between 9 and 10 (inclusive of 9 and exclusive of 10)
      // Adjusted to generate a float between 9 and 10, since the upper limit is non-inclusive
      return 9 + Math.random();
    }
  }

  static async getDemo(id: string) {
    const db = DBFactory.createDatabase();
    const data = await db.get({
      collection: "demos",
      path: `demos/${id}`,
    });

    return Demo.fromMap(data);
  }

  static async createStudentCriteriaData(
    uid: string,
    classroomId: string,
    classroomName: string,
    assignments: Assignments,
    students: Students,
    criteriaGroups: CriteriaGroups
  ) {
    const db = useFirestore();

    // const allCriteriaStatsRef = collection(
    //   db,
    //   `assignmentStatistics/default/criteria`
    // );
    // const allCriteriaStatsQuery = query(
    //   allCriteriaStatsRef,
    //   where("teacherId", "==", uid)
    // );
    // const allCriteriaStatsSnapshot = await getDocs(allCriteriaStatsQuery);

    // const deleteBatch = writeBatch(db);
    // for (var snapshot of allCriteriaStatsSnapshot.docs) {
    //   deleteBatch.delete(snapshot.ref);
    // }

    // await deleteBatch.commit();

    for (let assignment of assignments) {
      for (let student of students) {
        const batch = writeBatch(db);

        for (let group of criteriaGroups) {
          for (let criteriaId of group.checklistCriteriaIds) {
            const criteriaTransaction = {
              documentId: "",
              revisionId: "",
              classroomId: assignment.classroomId,
              teacherId: uid,
              studentId: student.id,
              studentName: student.name,
              assignmentId: assignment.id,
              assignmentName: assignment.name,
              checklistId: "",
              checklistName: "",
              sectionId: "",
              checklistCriteriaId: criteriaId,
              checklistCriteriaLabel: "",
              // Set earned points to a random number between 0 and 10
              earnedPoints: DemosService.generateWeightedRandom(),
              maxPoints: 10,
              lastUpdatedTimestamp: new Date().getTime(),
            } as CriteriaScoreStatisticTransaction;

            const criteriaTransactionRef = doc(
              db,
              `assignmentStatistics/default/criteria/${uid}-${assignment.id}-${student.id}-${criteriaId}`
            );

            batch.set(criteriaTransactionRef, criteriaTransaction);
          }
        }

        await batch.commit();
      }
    }
  }

  static async createAssignmentDocs(
    userId: string,
    classroomId: string,
    classroomName: string,
    assignmentId: string,
    assignmentName: string,
    classroomStudents: Students
  ) {
    const db = useFirestore();
    const batch = writeBatch(db);

    const documentIdToCopy = "ESUi41TJ68DsIle6nyHY";

    const documentRef = doc(db, `documents/${documentIdToCopy}`);
    const documentSnapshot = await getDoc(documentRef);
    const submittedDocument = {
      ...documentSnapshot.data(),
      id: documentIdToCopy,
    } as SubmittedDocument;

    submittedDocument.classroomId = classroomId;
    submittedDocument.classroomName = classroomName;
    submittedDocument.assignmentId = assignmentId;
    submittedDocument.assignmentName = assignmentName;

    for (var student of classroomStudents) {
      await this.copyDocumentToUser(
        submittedDocument,
        userId,
        student.id!,
        student.name
      );
    }

    // for (var student of classroomStudents) {
    //   const submittedDocument = {
    //     createOnTimestamp: new Date().getTime(),
    //     lastUpdatedTimestamp: new Date().getTime(),
    //     state: DocumentState.graded,
    //     doesNOTExpectAssignment: false,
    //     isLate: false,
    //     name: `${student.name}_${assignmentName}.docx`,
    //     userId: userId,
    //     assignmentId: assignmentId,
    //     assignmentName: assignmentName,
    //     classroomId: classroomId,
    //     classroomName: classroomName,
    //     studentId: student.id,
    //     studentName: student.name,
    //     enteredName: student.name,
    //     maxPoints: 100,
    //     // Set earnedPoints to a random number between 50 and 100
    //     earnedPoints: Math.floor(Math.random() * 51) + 50,
    //   } as SubmittedDocument;

    //   const documentRef = doc(db, `documents/${userId}-${useGenerateUID()}`);
    //   batch.set(documentRef, submittedDocument);
    // }

    // await batch.commit();
  }

  static async createDemo(
    classrooms: Classrooms,
    assignments: Assignments,
    students: Students,
    submissions: SubmittedDocuments,
    criteriaTransactions: CriteriaScoreStatisticTransaction[]
  ) {
    let modelsToSave = [] as BaseModel[];

    const demo = new Demo({
      name: "New Demo",
    });

    await demo.save();

    if (!demo.id) {
      return;
    }

    const formatClassrooms = await DemosService.formatClassrooms(
      `/demos/${demo.id}`,
      classrooms
    );
    modelsToSave = [...modelsToSave, ...formatClassrooms];

    const formatAssignments = DemosService.formatAssignments(
      `/demos/${demo.id}`,
      assignments
    );
    modelsToSave = [...modelsToSave, ...formatAssignments];

    const formatStudents = DemosService.formatStudents(
      `/demos/${demo.id}`,
      students
    );

    modelsToSave = [...modelsToSave, ...formatStudents];

    const db = DBFactory.createDatabase();
    await db.batchUpdate(modelsToSave);

    await DemosService.saveCriteriaTransactions(
      `/demos/${demo.id}`,
      criteriaTransactions
    );

    // Ok now whwat I need to do is fetc hthe full document which menas getting the revision, paragraphs, checklists, evaluative paragraphs etc
    // and then I need to create a batch so let's do the batch and separate the functions into a lot of writes and stuff
    // Ithink what I'll do is treat each document as a separte write though otherwise it'll probbly be tool large or just crat a nw batch

    const firestoreDB = useFirestore();

    let index = 1;

    const filteredSubmissions = submissions.filter((s) =>
      [
        DocumentState.grading,
        DocumentState.graded,
        DocumentState.pending,
        DocumentState.submitted,
      ].includes(s.state)
    );

    for (var submission of filteredSubmissions) {
      let batch = writeBatch(firestoreDB);
      const submissionRef = doc(
        firestoreDB,
        `demos/${demo.id}/documents/${submission.id}`
      );
      batch.set(submissionRef, submission);

      const originRef = doc(firestoreDB, `documents/${submission.id}`);
      const destinationRef = doc(
        firestoreDB,
        `demos/${demo.id}/documents/${submission.id}`
      );

      await DemosService.fetchAndUpdateFullDocument(
        batch,
        originRef,
        destinationRef
      );

      await batch.commit();
      index++;
    }
  }

  static async copyDemoToUser(userId: string, demoId: string) {
    const db = DBFactory.createDatabase();
    const firestoreDB = useFirestore();

    // Ok first we are going to need to delete all the exisitgn data for a user so we'll need to get all
    // of a users classrooms, assignemnts, students, and documents and delete them

    const modelsToDelete = [] as BaseModel[];

    const userClassroomData = await db.list(
      {
        collection: "classrooms",
        path: `classrooms`,
      },
      [
        {
          type: "where",
          field: "userId",
          operator: "==",
          value: userId,
        },
      ]
    );

    const userClassrooms = userClassroomData.map((data) => {
      return Classroom.fromMap(data);
    });

    modelsToDelete.push(...userClassrooms);

    const userAssignmentData = await db.list(
      {
        collection: "assignments",
        path: `assignments`,
      },
      [
        {
          type: "where",
          field: "userId",
          operator: "==",
          value: userId,
        },
      ]
    );

    const userAssignments = userAssignmentData.map((data) => {
      return Assignment.fromMap(data);
    });

    modelsToDelete.push(...userAssignments);

    const userStudentData = await db.list(
      {
        collection: "students",
        path: `students`,
      },
      [
        {
          type: "where",
          field: "userId",
          operator: "==",
          value: userId,
        },
      ]
    );

    const userStudents = userStudentData.map((data) => {
      return Student.fromMap(data);
    });

    modelsToDelete.push(...userStudents);

    await db.batchDelete(modelsToDelete);

    const userDocumentsRef = collection(firestoreDB, `documents`);
    const userDocumentsQuery = query(
      userDocumentsRef,
      where("userId", "==", userId)
    );
    const userDocumentsSnapshot = await getDocs(userDocumentsQuery);

    const deleteBatch = writeBatch(firestoreDB);

    for (var d of userDocumentsSnapshot.docs) {
      deleteBatch.delete(d.ref);
    }

    await deleteBatch.commit();

    const userCriteriaRef = collection(
      firestoreDB,
      `assignmentStatistics/default/criteria`
    );
    const userCriteriaQuery = query(
      userCriteriaRef,
      where("teacherId", "==", userId)
    );

    const userCriteriaSnapshot = await getDocs(userCriteriaQuery);

    let criteriaDeleteBatch = writeBatch(firestoreDB);
    let index = 0;
    for (var c of userCriteriaSnapshot.docs) {
      if (index >= 100) {
        await criteriaDeleteBatch.commit();
        criteriaDeleteBatch = writeBatch(firestoreDB);
        index = 0;
      }

      criteriaDeleteBatch.delete(c.ref);
    }

    await criteriaDeleteBatch.commit();

    ////////

    const demoPath = `demos/${demoId}`;

    const modelsToSave = [] as BaseModel[];

    const classroomData = await db.list({
      collection: `demos/${demoId}/classrooms`,
      path: `demos/${demoId}/classrooms`,
    });

    const classrooms = classroomData.map((data) => {
      return Classroom.fromMap(data);
    });

    const formattedClassrooms = await DemosService.formatClassrooms(
      "",
      classrooms,
      userId
    );
    modelsToSave.push(...formattedClassrooms);

    const assignmentData = await db.list({
      collection: `demos/${demoId}/assignments`,
      path: `demos/${demoId}/assignments`,
    });

    const assignments = assignmentData.map((data) => {
      return Assignment.fromMap(data);
    });

    const formattedAssignments = DemosService.formatAssignments(
      "",
      assignments,
      userId
    );
    modelsToSave.push(...formattedAssignments);

    const studentData = await db.list({
      collection: `demos/${demoId}/students`,
      path: `demos/${demoId}/students`,
    });

    const students = studentData.map((data) => {
      return Student.fromMap(data);
    });

    const formattedStudents = DemosService.formatStudents("", students, userId);
    modelsToSave.push(...formattedStudents);

    await db.batchUpdate(modelsToSave);

    // Copy transactions
    const criteriaTransactionData = await db.list({
      collection: `demos/${demoId}/assignmentStatistics/default/criteria`,
      path: `demos/${demoId}/assignmentStatistics/default/criteria`,
    });

    const criteriaTransactions = criteriaTransactionData.map((data) => {
      return {
        ...data,
      } as CriteriaScoreStatisticTransaction;
    });

    // only copy 20 criteriaTransactions for now
    // filter criteriaTransactions toa list of 20

    // let filteredCriteriaTransactions = criteriaTransactions.slice(0, 20);

    await DemosService.saveCriteriaTransactions(
      "",
      criteriaTransactions,
      userId
    );

    // Get all submitted documents for the demo

    const submissionsRef = collection(firestoreDB, `demos/${demoId}/documents`);
    const submissionsSnapshot = await getDocs(submissionsRef);

    let numGradedDocuments = 0;

    for (var submission of submissionsSnapshot.docs) {
      const submittedDocument = {
        ...submission.data(),
      } as SubmittedDocument;

      if (submittedDocument.state == DocumentState.graded) {
        numGradedDocuments++;
      }

      // Replace the document name and student name with the matching student name
      const student = formattedStudents.find(
        (s) =>
          s.id == DemosService.formatId(submittedDocument.studentId!, userId)
      );

      await this.copyDocumentToUser(
        submittedDocument,
        userId,
        student!.id!,
        student!.name,
        demoId
      );
    }

    // Last step is to update the number of graded documetns belonging to this student.
    const userRef = doc(firestoreDB, `users/${userId}`);
    await updateDoc(userRef, {
      numGradedDocuments: numGradedDocuments,
    });
  }

  static async copyDocumentToUser(
    submittedDocument: SubmittedDocument,
    userId: string,
    studentId: string,
    studentName: string,
    demoId?: string
  ) {
    const firestoreDB = useFirestore();
    let batch = writeBatch(firestoreDB);

    const documentCopy = { ...submittedDocument } as SubmittedDocument;

    documentCopy.id = `${userId}-${submittedDocument.id}`;
    documentCopy.demoId = demoId;

    documentCopy.userId = userId;

    if (documentCopy.classroomId) {
      documentCopy.classroomId = DemosService.formatId(
        documentCopy.classroomId,
        userId
      );
    }

    if (documentCopy.assignmentId) {
      documentCopy.assignmentId = DemosService.formatId(
        documentCopy.assignmentId,
        userId
      );
    }

    documentCopy.studentId = studentId;

    documentCopy.name = `${studentName}_${submittedDocument.assignmentName}.docx`;
    documentCopy.studentName = studentName;
    documentCopy.enteredName = studentName;

    documentCopy.id = `${userId}-${useGenerateUID()}`;

    const submissionRef = doc(firestoreDB, `/documents/${documentCopy.id}`);
    batch.set(submissionRef, documentCopy);

    // // OK here is whervare i need to update the full paths now. The interesting this is that I need to
    // // Figure out the path to get the document from IEW am I getting th
    // // Ok so I think I need a documents origin ref and destination ref

    const documentsOriginRef = doc(
      firestoreDB,
      demoId != undefined
        ? `/demos/${demoId}/documents/${submittedDocument.id}`
        : `/documents/${submittedDocument.id}`
    );
    const documentDestinationRef = doc(
      firestoreDB,
      `/documents/${submissionRef.id}`
    );

    await DemosService.fetchAndUpdateFullDocument(
      batch,
      documentsOriginRef,
      documentDestinationRef
    );

    await batch.commit();
  }

  static async formatClassrooms(
    destinationPath: string,
    classrooms: Classrooms,
    userId?: string
  ) {
    return classrooms.map((classroom) => {
      classroom.id = DemosService.formatId(classroom.id!, userId);
      classroom.externalId = DemosService.formatId(classroom.id!, userId);
      classroom.externalProvider = "iew";

      if (userId) {
        classroom.userId = userId;
      }

      classroom.modelDatabaseConfig = {
        collection: `${destinationPath}/classrooms`,
        path: `${destinationPath}/classrooms/${classroom.id}`,
      };

      return classroom;
    });
  }

  static formatAssignments(
    destinationPath: string,
    assignments: Assignments,
    userId?: string
  ) {
    return assignments.map((assignment, index) => {
      assignment.id = DemosService.formatId(assignment.id!, userId);
      assignment.externalId = assignment.id;
      assignment.externalProvider = "iew";
      assignment.classroomId = DemosService.formatId(
        assignment.classroomId,
        userId
      );

      if (userId) {
        assignment.userId = userId;
      }

      assignment.modelDatabaseConfig = {
        collection: `${destinationPath}/assignments`,
        path: `${destinationPath}/assignments/${assignment.id}`,
      };

      return assignment;
    });
  }

  static formatStudents(
    destinationPath: string,
    students: Students,
    userId?: string
  ) {
    return students.map((student) => {
      student.id = DemosService.formatId(student.id!, userId);
      student.externalId = student.id;
      student.externalProvider = "iew";
      student.classroomIds = student.classroomIds.map((classroomId) =>
        DemosService.formatId(classroomId, userId)
      );

      if (userId) {
        student.userId = userId;
      }

      student.modelDatabaseConfig = {
        collection: `${destinationPath}/students`,
        path: `${destinationPath}/students/${student.id}`,
      };

      // Generate a random student name:
      // student.name = HumanNames.allRandom();

      return student;
    });
  }

  static async saveCriteriaTransactions(
    destinationPath: string,
    criteriaTransactions: CriteriaScoreStatisticTransaction[],
    userId?: string
  ) {
    let batch = writeBatch(useFirestore());
    let index = 0;

    for (let transaction of criteriaTransactions) {
      if (index >= 100) {
        await batch.commit();
        batch = writeBatch(useFirestore());
      }

      let criteriaTransaction = {
        ...transaction,
      } as CriteriaScoreStatisticTransaction;

      criteriaTransaction.documentId = DemosService.formatId(
        criteriaTransaction.documentId,
        userId
      );
      criteriaTransaction.revisionId = DemosService.formatId(
        criteriaTransaction.revisionId,
        userId
      );
      criteriaTransaction.classroomId = DemosService.formatId(
        criteriaTransaction.classroomId,
        userId
      );
      criteriaTransaction.teacherId = userId ?? criteriaTransaction.teacherId;

      criteriaTransaction.studentId = DemosService.formatId(
        criteriaTransaction.studentId,
        userId
      );
      criteriaTransaction.assignmentId = DemosService.formatId(
        criteriaTransaction.assignmentId,
        userId
      );
      criteriaTransaction.checklistId = criteriaTransaction.checklistId;
      criteriaTransaction.sectionId = DemosService.formatId(
        criteriaTransaction.sectionId,
        userId
      );
      criteriaTransaction.checklistCriteriaId =
        criteriaTransaction.checklistCriteriaId;

      const criteriaTransactionRef = doc(
        useFirestore(),
        `${destinationPath}/assignmentStatistics/default/criteria/${criteriaTransaction.teacherId}-${criteriaTransaction.assignmentId}-${criteriaTransaction.studentId}-${criteriaTransaction.checklistCriteriaId}`
      );

      batch.set(criteriaTransactionRef, criteriaTransaction);

      index++;
    }

    await batch.commit();
  }

  static async saveDocumentAsSample(documentId: string) {
    const firestoreDB = useFirestore();
    const originRef = doc(firestoreDB, `documents/${documentId}`);
    const destinationref = doc(firestoreDB, `sampleDocuments/${documentId}`);

    const documentSnapshot = await getDoc(originRef);
    const document = documentSnapshot.data() as SubmittedDocument;

    const batch = writeBatch(firestoreDB);

    batch.set(destinationref, document);

    await this.fetchAndUpdateFullDocument(batch, originRef, destinationref);

    await batch.commit();
  }

  static async copySampleToUser(documentId: string, userId: string) {
    const firestoreDB = useFirestore();
    const originRef = doc(firestoreDB, `sampleDocuments/${documentId}`);
    const destinationRef = doc(
      firestoreDB,
      `documents/${userId}-${documentId}-${useGenerateUID()}`
    );

    const timestamp = new Date().getTime();
    const fireTimestamp = Timestamp.now();

    const sampleDocumentSnapshot = await getDoc(originRef);
    const sampleDocument = sampleDocumentSnapshot.data() as SubmittedDocument;
    sampleDocument.userId = userId;
    sampleDocument.state = DocumentState.converting;
    sampleDocument.lastUpdatedTimestamp = timestamp;
    sampleDocument.createOnTimestamp = timestamp;
    sampleDocument.submittedAtTimestamp = fireTimestamp;

    await setDoc(destinationRef, sampleDocument);
    const batch = writeBatch(firestoreDB);

    await this.fetchAndUpdateFullDocument(batch, originRef, destinationRef);

    await batch.commit();

    await updateDoc(destinationRef, {
      state: DocumentState.submitted,
      createdOnTimestamp: timestamp,
      lastUpdatedTimestamp: timestamp,
      submittedAtTimestamp: fireTimestamp,
    });
  }

  static async fetchAndUpdateFullDocument(
    batch: WriteBatch,
    originRef: DocumentReference<DocumentData>,
    destinationRef: DocumentReference<DocumentData>
  ) {
    const revisionsRef = collection(originRef, `/revisions`);
    const revisionSnapshot = await getDocs(revisionsRef);
    const revisions = revisionSnapshot.docs.map((d) => {
      return {
        ...d.data(),
        id: d.id,
      } as Revision;
    });

    if (revisions.length == 0) return;

    const revision = revisions[0];
    const revisionRef = revisionSnapshot.docs[0].ref;

    const revisionDemoRef = doc(destinationRef, `/revisions/${revision.id}`);
    batch.set(revisionDemoRef, revision);

    // Do this for paragraphs
    await DemosService.fetchAndSetParagraphs(
      batch,
      revisionRef,
      revisionDemoRef
    );

    // Evaluative Paragraphs
    await DemosService.fetchAndSetEvaluativeParagraphs(
      batch,
      revisionRef,
      revisionDemoRef
    );

    // Evaluation results
    await DemosService.fetchAndSetEvalautionResults(
      batch,
      revisionRef,
      revisionDemoRef
    );

    // Annotatinos
    await DemosService.fetchAndSetAnnotations(
      batch,
      revisionRef,
      revisionDemoRef
    );

    // checklists
    await DemosService.fetchAndSetChecklists(
      batch,
      revisionRef,
      revisionDemoRef
    );
  }

  static async fetchAndSetParagraphs(
    batch: WriteBatch,
    originRef: DocumentReference<DocumentData>,
    destinationRef: DocumentReference<DocumentData>
  ) {
    const firestoreDB = useFirestore();

    const paragraphsRef = collection(originRef, `/paragraphs`);
    const paragraphsSnapshot = await getDocs(paragraphsRef);

    for (var snapshot of paragraphsSnapshot.docs) {
      const paragraph = snapshot.data() as Paragraph;

      const paragraphDemoRef = doc(destinationRef, `paragraphs/${snapshot.id}`);
      batch.set(paragraphDemoRef, paragraph);
    }
  }

  static async fetchAndSetEvaluativeParagraphs(
    batch: WriteBatch,
    originRef: DocumentReference<DocumentData>,
    destinationRef: DocumentReference<DocumentData>
  ) {
    const firestoreDB = useFirestore();

    const paragraphsRef = collection(originRef, `/evaluativeParagraphs`);
    const paragraphsSnapshot = await getDocs(paragraphsRef);

    for (var snapshot of paragraphsSnapshot.docs) {
      const paragraph = snapshot.data() as Paragraph;

      const paragraphDemoRef = doc(
        destinationRef,
        `evaluativeParagraphs/${snapshot.id}`
      );
      batch.set(paragraphDemoRef, paragraph);
    }
  }

  static async fetchAndSetEvalautionResults(
    batch: WriteBatch,
    originRef: DocumentReference<DocumentData>,
    destinationRef: DocumentReference<DocumentData>
  ) {
    const firestoreDB = useFirestore();

    const resultsRef = collection(originRef, `/evaluationResults`);
    const resultsSnapshot = await getDocs(resultsRef);

    for (var snapshot of resultsSnapshot.docs) {
      const result = snapshot.data() as Paragraph;

      const resultDemoRef = doc(
        destinationRef,
        `evaluationResults/${snapshot.id}`
      );
      batch.set(resultDemoRef, result);
    }
  }

  static async fetchAndSetAnnotations(
    batch: WriteBatch,
    originRef: DocumentReference<DocumentData>,
    destinationRef: DocumentReference<DocumentData>
  ) {
    const firestoreDB = useFirestore();

    const annotationsRef = collection(originRef, `/annotations`);
    const annotationsSnapshot = await getDocs(annotationsRef);

    for (var snapshot of annotationsSnapshot.docs) {
      const annotation = snapshot.data() as Paragraph;

      const annotationDemoRef = doc(
        destinationRef,
        `annotations/${snapshot.id}`
      );
      batch.set(annotationDemoRef, annotation);
    }
  }

  static async fetchAndSetChecklists(
    batch: WriteBatch,
    originRef: DocumentReference<DocumentData>,
    destinationRef: DocumentReference<DocumentData>
  ) {
    const firestoreDB = useFirestore();

    const checklistsRef = collection(originRef, `/checklists`);
    const checklistsSnapshot = await getDocs(checklistsRef);

    for (var snapshot of checklistsSnapshot.docs) {
      const checklist = snapshot.data() as Paragraph;

      const checklistDemoRef = doc(destinationRef, `checklists/${snapshot.id}`);
      batch.set(checklistDemoRef, checklist);

      const sectionsReference = collection(
        checklistsRef,
        `${snapshot.id}/sections`
      );

      await DemosService.fetchAndSetChecklistSectionsRecursive(
        batch,
        checklistDemoRef,
        sectionsReference
      );
    }
  }

  static async fetchAndSetChecklistSectionsRecursive(
    batch: WriteBatch,
    demoChecklistRef: DocumentReference<DocumentData>,
    checklistSectionRef: CollectionReference<DocumentData>
  ) {
    const sectionSnapshot = await getDocs(checklistSectionRef);

    for (var snapshot of sectionSnapshot.docs) {
      const section = snapshot.data() as Paragraph;

      const sectionDemoRef = doc(demoChecklistRef, `sections/${snapshot.id}`);
      batch.set(sectionDemoRef, section);

      const subSectionsReference = collection(
        checklistSectionRef,
        `${snapshot.id}/sections`
      );

      await DemosService.fetchAndSetChecklistSectionsRecursive(
        batch,
        sectionDemoRef,
        subSectionsReference
      );
    }
  }

  static formatId(id: string, userId?: string) {
    try {
      let newId = id.replace("sakai", "iew");

      if (userId) {
        const idParts = newId.split("-");
        idParts[1] = userId;
        newId = idParts.join("-");
      }

      return newId;
    } catch (error) {
      return id;
    }
  }

  static async resetDemo(userId: string, documents: SubmittedDocuments) {
    // so I think I want to do this. Loops over each document. If there's a demo id, get the demo document verion otherwise delete it.
    // oh interesting. It's only grabbing ungraded documents.

    const firestoreDB = useFirestore();

    for (var document of documents) {
      const documentRef = doc(firestoreDB, `documents/${document.id}`);

      const originalDocumentId = document.id!.replace(`${userId}-`, "");

      const batch = writeBatch(firestoreDB);

      if (document.demoId == undefined) {
        batch.delete(documentRef);
        await batch.commit();
        continue;
      }

      const demoDocumentRef = doc(
        firestoreDB,
        `demos/${document.demoId}/documents/${originalDocumentId}`
      );

      const demoDocumentSnapshot = await getDoc(demoDocumentRef);

      const demoDocument = demoDocumentSnapshot.data() as SubmittedDocument;

      // If the document hasn't been updated, skip it
      if (
        demoDocument.lastUpdatedTimestamp == document.lastUpdatedTimestamp &&
        demoDocument.state == document.state &&
        demoDocument.earnedPoints == document.earnedPoints
      ) {
        continue;
      }

      // Delete the original document's paragraphs, checklist, etc and then call the fetch and copy full document function

      const revisionsRef = collection(
        firestoreDB,
        `${documentRef.path}/revisions`
      );
      const revisionsSnapshot = await getDocs(revisionsRef);

      for (var snapshot of revisionsSnapshot.docs) {
        batch.delete(snapshot.ref);
      }

      const baseRevisionRef = revisionsSnapshot.docs[0].ref;

      const paragraphsRef = collection(
        firestoreDB,
        `/${baseRevisionRef.path}/paragraphs`
      );
      const paragraphsSnapshot = await getDocs(paragraphsRef);

      for (var snapshot of paragraphsSnapshot.docs) {
        batch.delete(snapshot.ref);
      }

      const evaluativeParagraphsRef = collection(
        firestoreDB,
        `${baseRevisionRef.path}/evaluativeParagraphs`
      );

      const evaluativeParagraphsSnapshot = await getDocs(
        evaluativeParagraphsRef
      );

      for (var snapshot of evaluativeParagraphsSnapshot.docs) {
        batch.delete(snapshot.ref);
      }

      const evaluationResultsRef = collection(
        firestoreDB,
        `${baseRevisionRef.path}/evaluationResults`
      );
      const evaluationResultsSnapshot = await getDocs(evaluationResultsRef);

      for (var snapshot of evaluationResultsSnapshot.docs) {
        batch.delete(snapshot.ref);
      }

      const annotationsRef = collection(
        firestoreDB,
        `${baseRevisionRef.path}/annotations`
      );
      const annotationsSnapshot = await getDocs(annotationsRef);

      for (var snapshot of annotationsSnapshot.docs) {
        batch.delete(snapshot.ref);
      }

      const checklistsRef = collection(
        firestoreDB,
        `${baseRevisionRef.path}/checklists`
      );
      const checklistsSnapshot = await getDocs(checklistsRef);

      for (var snapshot of checklistsSnapshot.docs) {
        batch.delete(snapshot.ref);
      }

      await batch.commit();

      const newBatch = writeBatch(firestoreDB);

      const destinationDocRef = doc(firestoreDB, documentRef.path);

      document.lastUpdatedTimestamp = demoDocument.lastUpdatedTimestamp;
      document.state = demoDocument.state;

      newBatch.set(destinationDocRef, document);

      await DemosService.fetchAndUpdateFullDocument(
        newBatch,
        demoDocumentRef,
        destinationDocRef
      );

      await newBatch.commit();
    }

    const classrooms = await ClassroomsService.listUserClassrooms(userId);

    for (let classroom of classrooms) {
      const classroomAssignments = await AssignmentsService.listAssignments(
        classroom.id!,
        userId
      );
      const sortedAssignments = useSortAssignments(classroomAssignments);
      const lastAssignment = sortedAssignments[sortedAssignments.length - 1];

      // Set the open timestamp to yesterday
      const openTimestamp = new Date().getTime() - 86400000;

      // Set the due date to yesterday + 5 days
      const dueTimestamp = openTimestamp + 432000000;

      // Set the close date to openTimestamp + 7 days
      const closeTimestamp = openTimestamp + 604800000;

      if (lastAssignment) {
        lastAssignment.openTimestamp = openTimestamp;
        lastAssignment.dueTimestamp = dueTimestamp;
        lastAssignment.closeTimestamp = closeTimestamp;
        await lastAssignment.save();
      }

      const students = await StudentsService.listClassroomStudents(
        classroom.id!,
        userId
      );
    }
  }

  static async fetchAllUserDocuments(userId: string) {
    const db = useFirestore();
    const documentsRef = collection(db, `documents`);
    const documentsQuery = query(documentsRef, where("userId", "==", userId));
    const documentsSnapshot = await getDocs(documentsQuery);

    return documentsSnapshot.docs.map((d) => {
      return {
        ...d.data(),
        id: d.id,
      } as SubmittedDocument;
    });
  }

  static async fetchAllCriteriaTransactions(userId: string) {
    const db = useFirestore();
    const criteriaRef = collection(db, `assignmentStatistics/default/criteria`);
    const criteriaQuery = query(criteriaRef, where("teacherId", "==", userId));
    const criteriaSnapshot = await getDocs(criteriaQuery);

    return criteriaSnapshot.docs.map((d) => {
      return {
        ...d.data(),
      } as CriteriaScoreStatisticTransaction;
    });
  }
}
